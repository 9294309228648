
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    // todo props;
  },
  data() {
    return {};
  },
  setup() {
    // todo setup
    return {};
  },
  methods: {
    // todo method
  },
});
